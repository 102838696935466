export const FirebaseConfig = {
	"projectId": "ebakkie-fe711",
	"appId": "1:702052576066:web:7f961ddcf7fa5e6a1ff6db",
	"databaseURL": "https://ebakkie-fe711-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "ebakkie-fe711.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDpojAhioOVH56UY9qWNBDhJjhFOxDsPj4",
	"authDomain": "ebakkie-fe711.firebaseapp.com",
	"messagingSenderId": "702052576066",
	"measurementId": "G-QPZP759YPM"
};